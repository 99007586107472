import { apiHeaders } from '../../utils/utils'
import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'
import { LOCAL_STORAGE_KEYS } from '@/utils/constants'

export const registerUser = (data) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.register,
    data: data,
  })

export const verifyUser = (token: string) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.verifyUser,
    params: {
      token: token,
    },
  })

export const loginUser = (data) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.login,
    data: data,
  })

export const refreshToken = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.refreshToken,
    headers: {
      'Content-Type': 'application/json',
      'Refresh-Token': localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),
      'Login-Method': localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD),
    },
  })

export const getUser = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.userInfo,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })

export const getUserOrders = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.userOrders,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })

export const getUserOrderByToken = (token) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.userOrders,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
    uid: token,
  })

export const putUser = (data) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.userInfo,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
    data: data,
  })

export const putUserPassword = (data) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.userInfo,
    uid: '/change-password',
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
    data: data,
  })

export const forgetPassword = (email) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.forgetPassword,
    data: {
      email: email,
    },
  })

export const putResetPassword = (data, token: string) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.resetPassword,
    uid: token,
    data: {
      password: data,
    },
  })

export const putUserOrder = (tokenValue: string) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.userOrders,
    uid: `${tokenValue}/cancel`,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })

export const getUserCards = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.cards,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })

export const postUserCards = (data: { token: string; last4: string }) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.cards,
    data: data,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })

export const getUserBalance = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.balance,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
  })
