import { Country, Items } from '../../interfaces/common/redux'

export interface ParametersReducerState {
  loading: boolean
  countries?: Country[]
  items?: Items
  error?: any
}

export const initialProductsState: ParametersReducerState = {
  loading: false,
}

const parameterReducer = (
  state = initialProductsState,
  action
): ParametersReducerState => {
  switch (action.type) {
    case 'GET_PARAMETERS_REQUEST':
    case 'GET_COUNTRIES_REQUEST':
      return { ...state, loading: true }
    case 'GET_PARAMETERS_SUCCESS':
      return { ...state, loading: false, items: action.parameters }
    case 'GET_COUNTRIES_SUCCESS':
      return { ...state, loading: false, countries: action.countries }
    case 'GET_PARAMETERS_FAILURE':
    case 'GET_COUNTRIES_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default parameterReducer
