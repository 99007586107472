import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'
import { BillingAddress, CheckoutAddress } from '../../typings/base'
import { apiHeaders } from '../../utils/utils'

export const getCheckout = (token: string) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.checkout,
    uid: token,
  })

export const createCheckoutAdress = (token: string, item: CheckoutAddress) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.checkout,
    uid: `${token}/address`,
    data: item,
  })

export const getCheckoutShippingMethods = (token: string) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.checkout,
    uid: `${token}/shipping/`,
  })

export const createCheckoutShipping = (token: string, method: string) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.checkout,
    uid: `${token}/shipping/0`,
    data: {
      method: method,
    },
  })

export const createCheckoutPayment = (token: string) =>
  axiosWrapper({
    method: EMethods.put,
    url: ERequestUrl.checkout,
    uid: `${token}/payment/0`,
    data: {
      method: 'bank_transfer',
    },
  })

export const postCheckout = (token: string, data) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.stripe,
    data: data,
    uid: `${token}/execute`,
  })

export const getLastUsedBillingAddress = () =>
  axiosWrapper<BillingAddress>({
    method: EMethods.get,
    url: ERequestUrl.shipments,
    uid: 'last-billing-address',
    headers: apiHeaders(localStorage.getItem('connexion-type')),
  })
