import { Product } from '../../interfaces/products/shop'

export interface ShopState {
  items: any[]
  loading: boolean
  currentSlug: string
  isCumulative: boolean
  savedState: {
    currentPage: number
    selectedCategory: string
    sort: Record<string, 'ASC' | 'DESC'> | null
    filters: any
    products: Product[]
    scrollPosition: number
    isDrawerOpen: boolean
    currentQuery: any
    currentPath: string
  } | null
}

const initialState: ShopState = {
  items: [],
  loading: false,
  currentSlug: '',
  isCumulative: false,
  savedState: null,
}

export const shopPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_SHOP_STATE':
      return {
        ...state,

        savedState: {
          ...action.payload,

          timestamp: Date.now(), // Add timestamp to expire old state
        },
      }

    case 'RESTORE_SHOP_STATE':
      return {
        ...state,

        savedState: null,
      }

    // ... rest of your existing cases

    default:
      return state
  }
}
