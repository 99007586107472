import * as userService from '../services/user'
import { datadogRum } from '@datadog/browser-rum'
import { LOCAL_STORAGE_KEYS } from '@/utils/constants'

export const ACTIONS = {
  SAVE_USER_REQUEST: 'SAVE_USER_REQUEST',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_FAILURE: 'SAVE_USER_FAILURE',
  REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',
  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  PUT_USER_REQUEST: 'PUT_USER_REQUEST',
  PUT_USER_SUCCESS: 'PUT_USER_SUCCESS',
  PUT_USER_FAILURE: 'PUT_USER_FAILURE',
  PUT_USER_PASSWORD_REQUEST: 'PUT_USER_PASSWORD_REQUEST',
  PUT_USER_PASSWORD_SUCCESS: 'PUT_USER_PASSWORD_SUCCESS',
  PUT_USER_PASSWORD_FAILURE: 'PUT_USER_PASSWORD_FAILURE',
  GET_USER_ORDERS_REQUEST: 'GET_USER_ORDERS_REQUEST',
  GET_USER_ORDERS_SUCCESS: 'GET_USER_ORDERS_SUCCESS',
  GET_USER_ORDERS_FAILURE: 'GET_USER_ORDERS_FAILURE',
  GET_USER_ORDER_BY_TOKEN_REQUEST: 'GET_USER_ORDER_BY_TOKEN_REQUEST',
  GET_USER_ORDER_BY_TOKEN_SUCCESS: 'GET_USER_ORDER_BY_TOKEN_SUCCESS',
  GET_USER_ORDER_BY_TOKEN_FAILURE: 'GET_USER_ORDER_BY_TOKEN_FAILURE',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  PUT_USER_ORDER_REQUEST: 'PUT_USER_ORDER_REQUEST',
  PUT_USER_ORDER_SUCCESS: 'PUT_USER_ORDER_SUCCESS',
  PUT_USER_ORDER_FAILURE: 'PUT_USER_ORDER_FAILURE',
  GET_USER_CARDS_REQUEST: 'GET_USER_CARDS_REQUEST',
  GET_USER_CARDS_SUCCESS: 'GET_USER_CARDS_SUCCESS',
  GET_USER_CARDS_FAILURE: 'GET_USER_CARDS_FAILURE',
  POST_USER_CARD_REQUEST: 'POST_USER_CARD_REQUEST',
  POST_USER_CARD_SUCCESS: 'POST_USER_CARD_SUCCESS',
  POST_USER_CARD_FAILURE: 'POST_USER_CARD_FAILURE',
  SAVE_GHOST_USER: 'SAVE_GHOST_USER',
  DELETE_GHOST_USER: 'DELETE_GHOST_USER',
  GET_USER_BALANCE_REQUEST: 'GET_USER_BALANCE_REQUEST',
  GET_USER_BALANCE_SUCCESS: 'GET_USER_BALANCE_SUCCESS',
  GET_USER_BALANCE_FAILURE: 'GET_USER_BALANCE_FAILURE',
}

export const SAVE_USER_REQUEST = () => ({
  type: ACTIONS.SAVE_USER_REQUEST,
})

export const SAVE_USER_SUCCESS = (email: string) => ({
  type: ACTIONS.SAVE_USER_SUCCESS,
  email,
})

export const SAVE_USER_FAILURE = (err) => ({
  type: ACTIONS.SAVE_USER_FAILURE,
  err,
})

export function saveUser(email: string) {
  return (dispatch) => {
    dispatch(SAVE_USER_REQUEST())
    dispatch(SAVE_USER_SUCCESS(email))
  }
}

export const REGISTER_USER_REQUEST = () => ({
  type: ACTIONS.REGISTER_USER_REQUEST,
})

export const REGISTER_USER_SUCCESS = (token: string) => ({
  type: ACTIONS.REGISTER_USER_SUCCESS,
  token,
})

export const REGISTER_USER_FAILURE = (err) => ({
  type: ACTIONS.REGISTER_USER_FAILURE,
  err,
})

export function registerUser(data) {
  return (dispatch) => {
    dispatch(REGISTER_USER_REQUEST())
    if (data.email) datadogRum.setUserProperty('email', data.email)
    return userService.registerUser(data).then(
      (response) => dispatch(REGISTER_USER_SUCCESS(response)),
      (err) => dispatch(REGISTER_USER_FAILURE(err))
    )
  }
}

export const VERIFY_USER_REQUEST = () => ({
  type: ACTIONS.VERIFY_USER_REQUEST,
})

export const VERIFY_USER_SUCCESS = (user) => ({
  type: ACTIONS.VERIFY_USER_SUCCESS,
  user,
})

export const VERIFY_USER_FAILURE = (err) => ({
  type: ACTIONS.VERIFY_USER_FAILURE,
  err,
})

export function verifyUser(data) {
  return (dispatch) => {
    dispatch(VERIFY_USER_REQUEST())
    return userService.verifyUser(data).then(
      (response) => dispatch(VERIFY_USER_SUCCESS(response)),
      (err) => dispatch(VERIFY_USER_FAILURE(err))
    )
  }
}

export const LOGIN_USER_REQUEST = () => ({
  type: ACTIONS.LOGIN_USER_REQUEST,
})

export const LOGIN_USER_SUCCESS = (token: string) => ({
  type: ACTIONS.LOGIN_USER_SUCCESS,
  token,
})

export const LOGIN_USER_FAILURE = (err) => ({
  type: ACTIONS.LOGIN_USER_FAILURE,
  err,
})

export function loginUser(data) {
  return (dispatch) => {
    dispatch(LOGIN_USER_REQUEST())
    if (data.email) datadogRum.setUserProperty('email', data.email)
    return userService.loginUser(data).then(
      (response) => dispatch(LOGIN_USER_SUCCESS(response)),
      (err) => dispatch(LOGIN_USER_FAILURE(err))
    )
  }
}

export const LOGOUT_USER_SUCCESS = () => ({
  type: ACTIONS.LOGOUT_USER_SUCCESS,
})

export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)
    dispatch(LOGOUT_USER_SUCCESS())
  }
}

export const GET_USER_REQUEST = () => ({
  type: ACTIONS.GET_USER_REQUEST,
})

export const GET_USER_SUCCESS = (user) => {
  if (user.email) datadogRum.setUserProperty('email', user.email)
  return {
    type: ACTIONS.GET_USER_SUCCESS,
    user,
  }
}

export const GET_USER_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_FAILURE,
  err,
})

export function getUser() {
  return (dispatch) => {
    dispatch(GET_USER_REQUEST())
    return userService.getUser().then(
      (response) => dispatch(GET_USER_SUCCESS(response)),
      (err) => dispatch(GET_USER_FAILURE(err))
    )
  }
}

export const PUT_USER_REQUEST = () => ({
  type: ACTIONS.PUT_USER_REQUEST,
})

export const PUT_USER_SUCCESS = (user) => ({
  type: ACTIONS.PUT_USER_SUCCESS,
  user,
})

export const PUT_USER_FAILURE = (err) => ({
  type: ACTIONS.PUT_USER_FAILURE,
  err,
})

export function putUser(data) {
  return (dispatch) => {
    dispatch(PUT_USER_REQUEST())
    if (data.email) datadogRum.setUserProperty('email', data.email)
    return userService.putUser(data).then(
      (response) => dispatch(PUT_USER_SUCCESS(response)),
      (err) => dispatch(PUT_USER_FAILURE(err))
    )
  }
}

export const PUT_USER_PASSWORD_REQUEST = () => ({
  type: ACTIONS.PUT_USER_PASSWORD_REQUEST,
})

export const PUT_USER_PASSWORD_SUCCESS = (user) => ({
  type: ACTIONS.PUT_USER_PASSWORD_SUCCESS,
  user,
})

export const PUT_USER_PASSWORD_FAILURE = (err) => ({
  type: ACTIONS.PUT_USER_PASSWORD_FAILURE,
  err,
})

export function putUserPassword(data) {
  return (dispatch) => {
    dispatch(PUT_USER_PASSWORD_REQUEST())
    return userService.putUserPassword(data).then(
      (response) => dispatch(PUT_USER_PASSWORD_SUCCESS(response)),
      (err) => dispatch(PUT_USER_PASSWORD_FAILURE(err))
    )
  }
}

export const GET_USER_ORDERS_REQUEST = () => ({
  type: ACTIONS.GET_USER_ORDERS_REQUEST,
})

export const GET_USER_ORDERS_SUCCESS = (orders) => ({
  type: ACTIONS.GET_USER_ORDERS_SUCCESS,
  orders,
})

export const GET_USER_ORDERS_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_ORDERS_FAILURE,
  err,
})

export function getUserOrders() {
  return (dispatch) => {
    dispatch(GET_USER_ORDERS_REQUEST())
    return userService.getUserOrders().then(
      (response) => dispatch(GET_USER_ORDERS_SUCCESS(response)),
      (err) => dispatch(GET_USER_ORDERS_FAILURE(err))
    )
  }
}

export const GET_USER_ORDER_BY_TOKEN_REQUEST = () => ({
  type: ACTIONS.GET_USER_ORDER_BY_TOKEN_REQUEST,
})

export const GET_USER_ORDER_BY_TOKEN_SUCCESS = (orders) => ({
  type: ACTIONS.GET_USER_ORDER_BY_TOKEN_SUCCESS,
  orders,
})

export const GET_USER_ORDER_BY_TOKEN_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_ORDER_BY_TOKEN_FAILURE,
  err,
})

export function getUserOrderByToken(token: string) {
  return (dispatch) => {
    dispatch(GET_USER_ORDER_BY_TOKEN_REQUEST())
    return userService.getUserOrderByToken(token).then(
      (response) => dispatch(GET_USER_ORDER_BY_TOKEN_SUCCESS(response)),
      (err) => dispatch(GET_USER_ORDER_BY_TOKEN_FAILURE(err))
    )
  }
}

export const DELETE_USER_REQUEST = () => ({
  type: ACTIONS.DELETE_USER_REQUEST,
})

export const DELETE_USER_SUCCESS = () => ({
  type: ACTIONS.DELETE_USER_SUCCESS,
})

export function deleteUser() {
  return (dispatch) => {
    dispatch(DELETE_USER_SUCCESS())
  }
}

export const FORGET_PASSWORD_REQUEST = () => ({
  type: ACTIONS.FORGET_PASSWORD_REQUEST,
})

export const FORGET_PASSWORD_SUCCESS = (response) => ({
  type: ACTIONS.FORGET_PASSWORD_SUCCESS,
  response,
})

export const FORGET_PASSWORD_FAILURE = (err) => ({
  type: ACTIONS.FORGET_PASSWORD_FAILURE,
  err,
})

export function forgetPassword(email: string) {
  return (dispatch) => {
    dispatch(FORGET_PASSWORD_REQUEST())
    datadogRum.setUserProperty('email', email)
    return userService.forgetPassword(email).then(
      (response) => dispatch(FORGET_PASSWORD_SUCCESS(response)),
      (err) => dispatch(FORGET_PASSWORD_FAILURE(err))
    )
  }
}

export const RESET_PASSWORD_REQUEST = () => ({
  type: ACTIONS.RESET_PASSWORD_REQUEST,
})

export const RESET_PASSWORD_SUCCESS = (response) => ({
  type: ACTIONS.RESET_PASSWORD_SUCCESS,
  response,
})

export const RESET_PASSWORD_FAILURE = (err) => ({
  type: ACTIONS.RESET_PASSWORD_FAILURE,
  err,
})

export function putResetPassword(data: object, token: string) {
  return (dispatch) => {
    dispatch(RESET_PASSWORD_REQUEST())
    return userService.putResetPassword(data, token).then(
      (response) => dispatch(RESET_PASSWORD_SUCCESS(response)),
      (err) => dispatch(RESET_PASSWORD_FAILURE(err))
    )
  }
}

export const PUT_USER_ORDER_REQUEST = () => ({
  type: ACTIONS.PUT_USER_ORDER_REQUEST,
})

export const PUT_USER_ORDER_SUCCESS = (response) => ({
  type: ACTIONS.PUT_USER_ORDER_SUCCESS,
  response,
})

export const PUT_USER_ORDER_FAILURE = (err) => ({
  type: ACTIONS.PUT_USER_ORDER_FAILURE,
  err,
})

export function putUserOrder(tokenValue: string) {
  return (dispatch) => {
    dispatch(PUT_USER_ORDER_REQUEST())
    return userService.putUserOrder(tokenValue).then(
      (response) => dispatch(PUT_USER_ORDER_SUCCESS(response)),
      (err) => dispatch(PUT_USER_ORDER_FAILURE(err))
    )
  }
}

export const GET_USER_CARDS_REQUEST = () => ({
  type: ACTIONS.GET_USER_CARDS_REQUEST,
})

export const GET_USER_CARDS_SUCCESS = (cards) => ({
  type: ACTIONS.GET_USER_CARDS_SUCCESS,
  cards,
})

export const GET_USER_CARDS_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_CARDS_FAILURE,
  err,
})

export function getUserCards() {
  return (dispatch) => {
    dispatch(GET_USER_CARDS_REQUEST())
    return userService.getUserCards().then(
      (response) => dispatch(GET_USER_CARDS_SUCCESS(response)),
      (err) => dispatch(GET_USER_CARDS_FAILURE(err))
    )
  }
}

export const POST_USER_CARD_REQUEST = () => ({
  type: ACTIONS.POST_USER_CARD_REQUEST,
})

export const POST_USER_CARD_SUCCESS = (cards) => ({
  type: ACTIONS.POST_USER_CARD_SUCCESS,
  cards,
})

export const POST_USER_CARD_FAILURE = (err) => ({
  type: ACTIONS.POST_USER_CARD_FAILURE,
  err,
})

export function postUserCards(data: { token: string; last4: string }) {
  return (dispatch) => {
    dispatch(POST_USER_CARD_REQUEST())

    return userService.postUserCards(data).then(
      (response) => dispatch(POST_USER_CARD_SUCCESS(response)),
      (err) => dispatch(POST_USER_CARD_FAILURE(err))
    )
  }
}

export const SAVE_GHOST_USER = (ghostUserData) => ({
  type: ACTIONS.SAVE_GHOST_USER,
  ghostUserData,
})

export function saveGhostUser(data) {
  return (dispatch) => {
    dispatch(SAVE_GHOST_USER(data))
  }
}

export const DELETE_GHOST_USER = () => ({
  type: ACTIONS.DELETE_GHOST_USER,
})

export function deleteGhostUser() {
  return (dispatch) => {
    dispatch(DELETE_GHOST_USER())
  }
}

export const GET_USER_BALANCE_REQUEST = () => ({
  type: ACTIONS.GET_USER_BALANCE_REQUEST,
})

export const GET_USER_BALANCE_SUCCESS = (balance) => ({
  type: ACTIONS.GET_USER_BALANCE_SUCCESS,
  balance,
})

export const GET_USER_BALANCE_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_BALANCE_FAILURE,
  err,
})

export function getUserBalance() {
  return (dispatch) => {
    dispatch(GET_USER_BALANCE_REQUEST())
    return userService.getUserBalance().then(
      (response) => dispatch(GET_USER_BALANCE_SUCCESS(response)),
      (err) => dispatch(GET_USER_BALANCE_FAILURE(err))
    )
  }
}
