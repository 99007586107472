export enum AddressType {
  AREA = 'area',
}

export interface SearchAddress {
  hasHouseNumber: boolean
  streetNumber: number
  street: string
  postcode: string
  area: string
  areaCode: string
  city: string
  country_code: string
  country: string
}

export interface GoogleMapAddress {
  country: string
  streetNumber: number
  street: string
  area: string
  areaCode: string
  city: string
  postcode: string
  details: string
}

export interface AreaAddress {
  street: string
  city: string
  area: string
}

export type InputAddress = GoogleMapAddress | AreaAddress

export interface PayloadAddress {
  countryCode: string
  street: string
  details: string
  city: string
  postcode: string
  provinceName: string
  provinceCode: string
}

export interface AddressStore {
  isPanelOpen: boolean
  isValid: boolean
  payload: PayloadAddress
  search: SearchAddress
  input: InputAddress
}

export interface InputAddressReducerState {
  billing: AddressStore
  shipping: AddressStore
}

const initialAddressState = {
  search: undefined,
  payload: undefined,
  input: undefined,
  isValid: false,
  isPanelOpen: false,
}

export const initialState: InputAddressReducerState = {
  billing: {
    ...initialAddressState,
  },
  shipping: {
    ...initialAddressState,
  },
}

const inputAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PAYLOAD_ADDRESS':
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          payload: action.payload,
          isValid: action.isValid,
        },
      }
    case 'SAVE_SEARCH_ADDRESS':
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          search: action.search,
        },
      }
    case 'SAVE_INPUT_ADDRESS':
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          isPanelOpen: action.isPanelOpen,
          input: action.input,
        },
      }
    case 'RESET_INPUT_ADDRESS':
      return {
        ...state,
        [action.addressType]: initialState[action.addressType],
      }
    default:
      return state
  }
}

export default inputAddressReducer
